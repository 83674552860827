
export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const formatPhoneNumber = (phone: string) => {
  const cleanedPhone = (phone || '').replace(/\D/g, '');
  const match = cleanedPhone.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    return `${match[1] === '7' ? '+' : ''}${match[1]} ${match[2]} ${match[3]}-${match[4]}-${match[5]}`;
  }
  return phone;
};


export const urlWithSBPPay = () => {
  // eslint-disable-next-line no-restricted-globals
  return !(new RegExp(/card/, 'i').test(location.href));
}


export const uniqueId = (prefix: string) => {
  var uid = parseInt((new Date()).valueOf() +
    (Math.random() * 1000000).toFixed()).toString(36);
  return (prefix || '') + uid;
};

export function getQueryVariable(variable: string) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
      }
  }
  console.log('Query variable %s not found', variable);
}
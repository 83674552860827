import React, { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

type WrapperProps = {
  $showBackdrop?: boolean;
  $relative?: boolean;
};

const Wrapper = styled('div')<WrapperProps>(() => ({
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(243,246,249,.7)',
}));

const StyledText = styled(Typography)({
  '&.MuiTypography-root': {
    fontFamily: 'Whitney',
    fontWeight: 600,
    marginTop: '10px',
  },
});

export type LoaderProps = {
  showBackdrop?: boolean;
  relative?: boolean;
  size?: number;
  color?: 'black' | 'white' | 'primary';
  thickness?: number;
  text?: string;
  className?: string;
};

export const Loader: FC<LoaderProps> = ({
  size = 52,
  thickness = 3.5,
  showBackdrop = true,
  text,
  relative,
  className,
}) => (
  <Wrapper className={className} $showBackdrop={showBackdrop} $relative={relative}>
    <CircularProgress size={size} thickness={thickness} />
    {text && <StyledText>{text}</StyledText>}
  </Wrapper>
);

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import { CustomerWithPhone, Tariff } from './api';
import logo from './logo.svg';
import { FindCustomer } from './steps/find_customer';
import { SeasonTicket } from './steps/season_tickets';
import { UserAgreement } from './steps/user_agreement';
import { Pay } from './steps/pay';
import { getQueryVariable, urlWithSBPPay } from './helpers';
import './App.css';

function App() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [customer, setCustomer] = useState<CustomerWithPhone>();

  const hasMoneyInUrl = getQueryVariable('s');
  const money = hasMoneyInUrl ? (parseInt(atob(hasMoneyInUrl)) || 0) : 0;

  const [tariff, setTariff] = useState<Tariff | undefined>(Boolean(money) ? { id: 0, money: String(money), visible: '0', description: '', title: ''} : undefined);

  const isSbpPay = urlWithSBPPay();

  const saveCustomerAndNext = (data: CustomerWithPhone) => {
    setCustomer(data);
    handleNext();
  }

  const saveTicketAndNext = (tariff: Tariff) => {
    setTariff(tariff);
    handleNext();
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <Box sx={{ maxWidth: 480 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key='phone'>
            <StepLabel>
              Поиск ученика
            </StepLabel>
            <StepContent>
              <FindCustomer handleNext={saveCustomerAndNext} />
            </StepContent>
          </Step>
          {!Boolean(money) && (
            <Step key='seasonTicket'>
              <StepLabel>
                Абонемент
              </StepLabel>
              <StepContent>
                <SeasonTicket customer={customer} handleNext={saveTicketAndNext} handleBack={handleBack} />
              </StepContent>
            </Step>
          )}
          <Step key='userAgreement'>
            <StepLabel>
              Пользовательское соглашение
            </StepLabel>
            <StepContent>
              <UserAgreement tariff={tariff} customer={customer} handleNext={handleNext} handleBack={handleBack} />
            </StepContent>
          </Step>

          {isSbpPay && (
            <Step key='tick'>
              <StepLabel>
                Оплата
              </StepLabel>
              <StepContent>
                <Pay customer={customer} tariff={tariff} onReset={handleReset} />
              </StepContent>
            </Step>
          )}
        </Stepper>
      </Box>
    </div>
  );
}

export default App;

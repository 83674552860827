import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/system';
import { Alert, Badge, BadgeProps, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Card, { CardProps } from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import { CustomerWithPhone, Tariff, fetchTariff } from '../api';
import { Row } from '../components/row';
import { Loader } from '../components/loader';


const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: '32px',
    top: '42px',
    fontSize: '1.5rem',
    padding: '14px',
  },
}));


const StyledCard = styled(Card)<CardProps & {selected: boolean}>(({ theme }) => (props) => ({
  border: `1px solid ${props.selected ? theme.palette.primary.main : 'white'}`,
  background: `${props.selected ? '#ffffef' : ''}`
}));



interface TabPanelProps {
  customer?: CustomerWithPhone;
  handleNext: (tariff: Tariff) => void;
  handleBack: VoidFunction;
}

export const SeasonTicket: FC<TabPanelProps> = ({ customer, handleNext, handleBack }) => {
  const fetching = useRef(false);
  const [selectedTicket, setSelectedTicket] = useState<null | number>(null);
  const [tariff, setTariff] = useState<Tariff[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!customer || fetching.current) {
      fetching.current = false;
      return;
    }
    fetching.current = true;
    setLoading(true);
    fetchTariff(customer.phone)
      .then((res) => setTariff(res))
      .catch((e) => {
        setError(e.message || 'Ошибка получения данных')
      })
      .finally(() => {
        setLoading(false);
        fetching.current = false;
      })
  }, [customer])

  const onNextClick = () => {
    const selectedTariff = tariff.find(item => item.id === selectedTicket);
    selectedTariff && handleNext(selectedTariff);
  }

  return (
    <Box position="relative">
      <Typography>{`Добрый день, ${customer?.name_parent}! Выберите подходящий для вас абонемент`}</Typography>
      <Row>{error && <Alert severity="error">{error}</Alert>}</Row>
      {tariff.filter(item => item.visible !== "0").map(item => (
        <StyledBadge key={item.description} badgeContent={`${item.money}₽`} color="primary">
          <StyledCard sx={{ maxWidth: 345, minWidth: 345, marginTop: '8px', marginBottom: '8px' }} selected={item.id === selectedTicket}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" paddingRight={'52px'}>
                  {item.title}
                </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.description}
              </Typography>
              </CardContent>
            <CardActions>
              <Button
                onClick={() => setSelectedTicket(item.id)}
                size="small"
                variant="outlined"
                color="secondary"
                fullWidth
              >
                Выбрать
              </Button>
            </CardActions>
          </StyledCard>
        </StyledBadge>
      ))}
      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={onNextClick}
            sx={{ mt: 1, mr: 1 }}
            disabled={selectedTicket === null}
          >
            Далее
          </Button>
          <Button
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            Назад
          </Button>
        </div>
      </Box>
      {loading && <Loader />}  
    </Box>
  )
}
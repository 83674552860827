import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { Alert } from '@mui/material';

import { CustomerWithPhone, Tariff, paySBP, PayDtoSBP} from '../api';
import { Loader } from '../components/loader';
import { Row } from '../components/row';
import { uniqueId } from '../helpers';


interface TabPanelProps {
  tariff?: Tariff;
  customer?: CustomerWithPhone;
  onReset: () => void;
}

export const Pay: FC<TabPanelProps> = ({ onReset, tariff, customer}) => {
  const idWidget = useMemo(() => uniqueId('tinkoffWidgetContainer'), []);
  const container = useRef<HTMLDivElement>();
  const fetching = useRef(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [payParam, setPayParam] = useState<PayDtoSBP>();

  useEffect(() => {
    if (!customer?.id || !tariff?.money || fetching.current) {
      fetching.current = false;
      return;
    }
    fetching.current = true;
    setLoading(true);

    paySBP(customer.id, tariff.money)
      .then((res) => {
        if (!res.Token) throw(new Error('Ошибка получения ссылки на оплату'));
        setPayParam(res);
        
        const widgetParameters = {
          terminalKey: res.terminal_key || "1627488413300",
          paymentItems: [
            {
              container: document.getElementById(idWidget),
              paymentInfo: {
                  paymentData: {
                    Amount: res.money,
                    OrderId: res.order_id,
                    TerminalKey: res.terminal_key || "1627488413300",
                    Token: res.Token,
                  }
              }
            },
          ],
          paymentSystems: { TinkoffFps: {} },
        };

        return new Promise((resolve) => setTimeout(() => {
          // @ts-ignore
          resolve(window.initPayments(widgetParameters))
        }, 400));
      })
      .catch((e) => {
        setError(e.message || 'Ошибка попробуйте позднее')
      })
      .finally(() => {
        setLoading(false);
        fetching.current = false;
      })
  }, [customer, idWidget, tariff]);

  return (
    <Box position="relative" ref={container}>
      {error && <Row><Alert severity="error">{error}</Alert></Row>}
      <div id={idWidget}></div> 
      <form name="TinkoffPayForm"> 
        <input type="hidden" name="terminalkey" value={"1627488413300"}/>
        <input type="hidden" name="language" value="ru" /> 
          
        <input type="hidden" placeholder="Сумма заказа" name="amount" value={payParam?.money} required min="10.00"/>
        <input type="hidden" value={payParam?.order_id} placeholder="Номер заказа" name="order"/> 
        <input type="hidden" placeholder="Описание заказа" value = {payParam?.study_name} name="description"/> 
        <input type="hidden" placeholder="ФИО плательщика" value = {payParam?.name_parent} name="name"/> 
        <input type="hidden" placeholder="E-mail" name="email" value = {payParam?.email[0]} /> 
        <input type="hidden" placeholder="Контактный телефон" name="phone" value={`7${customer?.phone}`} /> 
      </form> 
      {loading && <Loader />}  
    </Box>
  )
}
